import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import { ViewGridIcon, ViewListIcon } from '@swe/shared/ui-kit/components/icon';
import Modal, { ModalProps } from '@swe/shared/ui-kit/components/modal';
import { ReactNode, useState } from 'react';

enum ViewMode {
  List = 'list',
  Grid = 'grid',
}

type BannersModalProps = Pick<ModalProps, 'visible' | 'onClose'> & {
  children: ReactNode | ((viewMode: ViewMode) => ReactNode);
};

const BannersModal = ({ children, ...modalProps }: BannersModalProps) => {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Grid);

  return (
    <Modal
      {...modalProps}
      heading="All Deals"
      headingTrail={
        <RadioGroup<ViewMode>
          name="viewMode"
          onChange={setViewMode}
          value={viewMode}
          variant="tag"
          size="md"
          options={[
            {
              value: ViewMode.Grid,
              icon: ViewGridIcon,
            },
            {
              value: ViewMode.List,
              icon: ViewListIcon,
            },
          ]}
        />
      }
      size="xl"
      showCloseButton
      ariaLabel="All Deals"
    >
      {typeof children === 'function' ? children(viewMode) : children}
    </Modal>
  );
};

export default BannersModal;
