import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { ProductCarousel } from 'entities/product/carousel';
import { StoreSaleType } from 'entities/shop/sale-type';

type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};
type Data = ProductCarousel[];

const NAME = '/Products/GetProductCarouselList';
const GetCarouselListEndpoint = createEndpoint<Params, Data>(NAME);

export default GetCarouselListEndpoint;
export { GetCarouselListEndpoint };
