import { useModalRoute } from '@swe/shared/providers/router/modal';
import { useCallback } from 'react';

import { ModalRoutes } from 'common/router/constants';
import BannersModal from 'domains/product/components/banners-modal';
import DiscountList from 'domains/product/containers/deals-list';

const useDealsModal = () => {
  const { open: _open, close, isOpened } = useModalRoute(ModalRoutes.Deals);
  const open = useCallback(() => _open(), [_open]);

  return {
    open,
    close,
    modal: (
      <BannersModal
        visible={isOpened}
        onClose={close}
      >
        {(viewMode) => <DiscountList viewMode={viewMode} />}
      </BannersModal>
    ),
  };
};

export { useDealsModal };
